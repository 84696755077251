<script setup>
import { Link } from '@inertiajs/vue3';
import { inject } from 'vue';

// Components
import Icon from '@/Components/Icon.vue';
import pinkArrow from '@i/pink-arrow.svg';
// import ImgMedia from '../Images/ImgMedia.vue';

const props = defineProps({
    blog: Object,
});

const route = inject('route');
const dayjs = inject('dayjs');
</script>

<template>
    <Link :href="route('blogs.show', { blog: props.blog.slug })">
        <article class="prose text-white cursor-pointer group">
            <div class="w-auto mb-5 rounded-lg bg-blue">
                <img
                    v-if="blog.header_image"
                    :src="blog.header_webp_src"
                    :alt="blog.header_image.alt_text"
                    :srcset="blog.header_webp_src_set"
                    sizes="(max-width: 768px) 100vw, 33vw"
                    class="object-cover w-full rounded-lg h-[240px]"
                />
            </div>

            <div class="mb-3">{{ dayjs(blog.publication_date).format('DD-MM-YYYY') }}</div>
            <h1 class="text-2xl font-medium text-white" v-html="blog.title"></h1>

            <span class="flex no-underline uppercase text-pink">
                {{ $t('Read more') }}
                <Icon :icon="pinkArrow" class="pl-5 m-0 transition-all duration-300 group-hover:pl-8" />
            </span>
        </article>
    </Link>
</template>
